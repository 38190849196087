import {Injectable} from '@angular/core';
import {WebSocketService} from './web-socket.service';
import {BehaviorSubject, Subscription} from 'rxjs';
import {UseMockDataService} from './use-mock-data.service';

export type WsUser = {
  name: string;
  connectDate: Date;
  latestAction?: string;
  latestActiveDate?: Date;
}

@Injectable({
  providedIn: 'root'
})
export class WsUsersService {
  private webSocketServiceReceived$?: Subscription;
  wsUsers$ = new BehaviorSubject<WsUser[]>([]);

  constructor(
    private webSocketService: WebSocketService,
    private useMockDataService: UseMockDataService
  ) {
  }

  init() {
    this.webSocketService.received$.subscribe((message) => {
      if (message.action.join('/') === 'wsUsers/list') this.handleWsUserUpdate(message.data as WsUser[]);
    });

    this.webSocketService.sendMessage({
      action: 'wsUsers/list/subscribe',
    });

    if (this.useMockDataService.useMockData) {
      this.webSocketService.mockReceiveMessage({
        action: 'wsUsers/list',
        data: [
          {
            name: 'John Doe',
            connectDate: new Date(),
            latestAction: 'customer/create',
            latestActiveDate: new Date(),
          }
        ]
      })
    }
  }

  private handleWsUserUpdate(wsUsers: WsUser[]) {
    this.wsUsers$.next([...wsUsers]);
  }

  destroy() {
    this.webSocketServiceReceived$?.unsubscribe();
    this.webSocketService.sendMessage({
      action: 'wsUsers/list/unsubscribe',
    })
  }
}
