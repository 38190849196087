import {Directive, HostListener, Input} from '@angular/core';
import {MatDatepicker, MatDateRangePicker} from '@angular/material/datepicker';
import {appConfig} from '../../../../app-config';

@Directive({
  selector: '[appDatepickerPreventInput]',
  standalone: true,
})
export class DatepickerPreventInputDirective {
  @Input() picker?: MatDatepicker<any> | MatDateRangePicker<any>;

  @HostListener('keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): boolean {
    return this.handleKeyDown(event);
  }

  @HostListener('focus', ['$event']) onFocus = () => {
    this.tryOpenPicker();
  };

  constructor() {
  }

  private handleKeyDown(event: KeyboardEvent): boolean {
    if (!event.key) return false;
    if (event.shiftKey || event.altKey || event.ctrlKey) return true;
    if (appConfig.specialKeys.includes(event.key)) return true;
    this.tryOpenPicker();
    return false;
  }

  private tryOpenPicker(): void {
    this.picker?.open();
  }
}
