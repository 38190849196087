import {NgModule} from '@angular/core';
import {CommonModule, NgOptimizedImage} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatMenuModule} from '@angular/material/menu';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatDialogModule} from '@angular/material/dialog';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {NgSelectModule} from '@ng-select/ng-select';
import {MtxDatetimepickerModule} from '@ng-matero/extensions/datetimepicker';
import {ButtonIsPendingDirective} from './directives/button-is-pending.directive';
import {AutofocusDirective} from './directives/autofocus.directive';
import {ElementBreakpointDirective} from './directives/element-breakpoint.directive';
import {DatepickerPreventInputDirective} from './directives/datepicker-prevent-input.directive';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatBadgeModule} from '@angular/material/badge';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';
import {HttpLoaderFactory} from '../../app.module';
import {PonyCalculateValuesPipe} from './pipes/pony-calculate-values.pipe';
import {PdfViewerModule} from 'ng2-pdf-viewer';
import {TimeAgoPipe} from './pipes/time-ago.pipe';
import {CustomerCalculateValuesPipe} from './pipes/customer-calculate-values.pipe';
import {GetLockedStatePipe} from './pipes/get-locked-state.pipe';
import {IntegerNumberInputDirective} from './directives/integer-number-input.directive';
import {NgxOnInitOnDestroyDirective} from './directives/ngx-on-init-on-destroy.directive';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {DecimalNumberInputDirective} from './directives/decimal-number-input.directive';

const pipesDeclareProvideAndExport: any = [
  PonyCalculateValuesPipe,
  CustomerCalculateValuesPipe,
  TimeAgoPipe,
  GetLockedStatePipe
];

const directivesImportAndExport = [
  NgOptimizedImage,
  AutofocusDirective,
  ButtonIsPendingDirective,
  DatepickerPreventInputDirective,
  ElementBreakpointDirective,
  IntegerNumberInputDirective,
  NgxOnInitOnDestroyDirective,
  DecimalNumberInputDirective
];

const modules = [
  // Angular
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  RouterModule,

  // MAT
  MatFormFieldModule,
  MatInputModule,
  MatMenuModule,
  MatButtonModule,
  MatIconModule,
  MatProgressSpinnerModule,
  MatDialogModule,
  MatBottomSheetModule,
  MatTooltipModule,
  MatSnackBarModule,
  MatToolbarModule,
  MatSlideToggleModule,
  MatBadgeModule,

  // 3rd party
  NgSelectModule,
  MtxDatetimepickerModule,
  PdfViewerModule,
  TranslateModule.forRoot({
    loader: {
      provide: TranslateLoader,
      useFactory: HttpLoaderFactory,
      deps: [HttpClient]
    }
  }),
];

@NgModule({
  declarations: [
    ...pipesDeclareProvideAndExport,
  ],
  imports: [
    ...directivesImportAndExport,
    ...modules
  ],
  exports: [
    ...directivesImportAndExport,
    ...pipesDeclareProvideAndExport,
    ...modules
  ],
  providers: [
    ...pipesDeclareProvideAndExport,
  ]
})
export class SharedModule {
}
