import { Pipe, PipeTransform } from '@angular/core';
import {UserService} from '../services/user.service';

export type LockState = 'LOCKED_BY_CURRENT_USER' | 'LOCKED_BY_OTHER' | 'UNLOCKED';

@Pipe({
  name: 'getLockedState'
})
export class GetLockedStatePipe implements PipeTransform {

  constructor(
    private userService: UserService,
  ) {
  }

  transform(idToCheck?: string | null): LockState {
    if (!idToCheck) return 'UNLOCKED';
    const userId = this.userService?.getUser()?.id;
    if(!userId) return 'LOCKED_BY_OTHER';
    if (idToCheck === userId) return 'LOCKED_BY_CURRENT_USER';
    return 'LOCKED_BY_OTHER';
  }

}
