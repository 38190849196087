import {AfterViewInit, ChangeDetectorRef, Directive, ElementRef} from '@angular/core';

@Directive({
  selector: '[appAutofocus]',
  standalone: true
})
export class AutofocusDirective implements AfterViewInit {

  constructor(
    private host: ElementRef,
    private changeDetectorRef: ChangeDetectorRef
  ) {
  }

  ngAfterViewInit() {
    this.host.nativeElement.focus();
    this.changeDetectorRef.detectChanges();
  }
}
