import {EnvironmentInterface} from './environment.interface';

declare const require: any;

export const environment: EnvironmentInterface = {
  isProduction: false,
  apiBaseUrl: 'https://test-spss.dysto.app:8888',
  apiPrefix: 'V1/webapp',
  useProxy: false,
  version: require('../../package.json').version,
  ws: 'https://test-spss.dysto.app:8888/hub',
  redirectToApiOffLineAfterApiTimeOut: false,
  apiServerResponseTimeoutMs: 120000,
  defaultUseMockData: true,
  features: {
    canUseMockData: true,
  }
};
