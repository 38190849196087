import {Pipe, PipeTransform} from '@angular/core';
import {Customer} from '../../../types/customer.interface';
import {GetLockedStatePipe} from './get-locked-state.pipe';

@Pipe({
  name: 'customerCalculateValues'
})
export class CustomerCalculateValuesPipe implements PipeTransform {

  constructor(
    private getLockedStatePipe: GetLockedStatePipe
  ) {
  }

  transform(customer: Customer): Customer {
    customer._lockState = this.getLockedStatePipe.transform(customer.lockedByUserId);

    return customer;
  }
}
