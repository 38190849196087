import {Pipe, PipeTransform} from '@angular/core';
import {Pony} from '../../../types/pony.interface';
import {GetLockedStatePipe} from './get-locked-state.pipe';
import {isNumeric} from '../../../../utils/is-numeric';

@Pipe({
  name: 'ponyCalculateValues'
})
export class PonyCalculateValuesPipe implements PipeTransform {

  constructor(
    private getLockedStatePipe: GetLockedStatePipe
  ) {
  }

  transform(pony: Pony): Pony {
    const auctionAgreementReady = this.getAuctionAgreementReady(pony);
    pony._auctionAgreementReady = auctionAgreementReady.auctionAgreementReady;
    pony._auctionAgreementBlockingReasons = auctionAgreementReady.blockingReasons;

    const conceptPurchaseAgreementReady = this.getConceptPurchaseAgreementReady(pony);
    pony._conceptPurchaseAgreementReady = conceptPurchaseAgreementReady.conceptPurchaseAgreementReady;
    pony._conceptPurchaseAgreementBlockingReasons = conceptPurchaseAgreementReady.blockingReasons;

    const purchaseAgreementReady = this.getPurchaseAgreementReady(pony);
    pony._purchaseAgreementReady = purchaseAgreementReady.purchaseAgreementReady;
    pony._purchaseAgreementBlockingReasons = purchaseAgreementReady.blockingReasons;

    pony._lockState = this.getLockedStatePipe.transform(pony.lockedByUserId);

    return pony;
  }

  getAuctionAgreementReady(pony: Pony): {
    auctionAgreementReady: boolean,
    blockingReasons: string[]
  } {
    const blockingReasons: string[] = [];
    if (!pony.name) blockingReasons.push('Geen naam ingevuld');
    if (!pony.dateOfBirth) blockingReasons.push('Geen geboortedatum ingevuld');
    if (!pony.chipNumber) blockingReasons.push('Geen chipnummer ingevuld');
    if (!pony.gender) blockingReasons.push('Geen geslacht ingevuld');
    if (!pony.sellerCustomerId) blockingReasons.push('Geen verkoper ingevuld');
    if (!pony.auctionAgreementPDFUrl) blockingReasons.push('Geen PDF beschikbaar');

    return {
      auctionAgreementReady: blockingReasons.length === 0,
      blockingReasons
    }
  }

  getConceptPurchaseAgreementReady(pony: Pony): {
    conceptPurchaseAgreementReady: boolean,
    blockingReasons: string[]
  } {
    const blockingReasons: string[] = [];
    if (!isNumeric(pony.sellingPrice)) blockingReasons.push('Geen verkoopprijs ingevuld');
    if (!pony.conceptPurchaseAgreementPDFUrl) blockingReasons.push('Geen PDF beschikbaar');

    return {
      conceptPurchaseAgreementReady: blockingReasons.length === 0,
      blockingReasons
    }
  }

  getPurchaseAgreementReady(pony: Pony): {
    purchaseAgreementReady: boolean,
    blockingReasons: string[]
  } {
    const blockingReasons: string[] = [];
    if (!pony.buyerCustomerId) blockingReasons.push('Geen koper ingevuld');
    if (!isNumeric(pony.sellingPrice)) blockingReasons.push('Geen verkoopprijs ingevuld');
    if (!pony.purchaseAgreementPDFUrl) blockingReasons.push('Geen PDF beschikbaar');

    return {
      purchaseAgreementReady: blockingReasons.length === 0,
      blockingReasons
    }
  }

}
