import {Injectable} from '@angular/core';
import {appConfig} from '../../../../app-config';
import {environment} from '../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UseMockDataService {
  useMockData: boolean = UseMockDataService.getMockDataInitialValue();

  static getMockDataInitialValue(): boolean {
    if (!environment.features.canUseMockData) return false;

    const localStorageValue = localStorage.getItem(appConfig.localStorageKeys.useMockData);
    if (localStorageValue) {
      return JSON.parse(localStorageValue);
    } else {
      return environment.defaultUseMockData;
    }
  }
}
