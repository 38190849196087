import {Injectable} from '@angular/core';

import {BehaviorSubject, Observable} from 'rxjs';
import {User} from '../../../types/user.interface';
import {appConfig} from '../../../../app-config';


@Injectable({
  providedIn: 'root'
})
export class UserService {
  private _user: User | undefined;
  private user: BehaviorSubject<User | undefined>;

  user$: Observable<User | undefined>;

  constructor() {
    this._user = undefined;
    this.user = new BehaviorSubject<User | undefined>(undefined);
    this.user$ = this.user.asObservable();
  }

  private triggerUpdate() {
    this.user.next(Object.assign({}, this._user));
  }

  setUser(user: User) {
    this._user = Object.assign({}, user);
    this.updateUser();
  }

  private updateUser() {
    localStorage.setItem(appConfig.localStorageKeys.user, JSON.stringify(this._user));
    this.triggerUpdate();
  }

  unSetUser() {
    this._user = undefined;
    localStorage.removeItem(appConfig.localStorageKeys.user);
    this.user.next(undefined);
  }

  getUser(): User | undefined {
    return this._user;
  }

  setApiTokenExpireDate(apiTokenExpireDate: Date) {
    if (!this._user) return;
    this._user.apiTokenExpireDate = apiTokenExpireDate;
    this.updateUser();
  }
}
