import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {FormControl, FormGroup} from '@angular/forms';
import {NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router} from '@angular/router';
import {environment} from 'src/environments/environment';
import {appConfig} from '../app-config';
import {NgSelectConfig} from '@ng-select/ng-select';
import {User} from './types/user.interface';
import {UseMockDataService} from './modules/shared/services/use-mock-data.service';
import {UserService} from './modules/shared/services/user.service';
import {MockHttpClientPipe} from './modules/shared/pipes/mock-data-fetch.pipe';
import {MatDialog} from '@angular/material/dialog';
import * as moment from 'moment';
import {HideMainNavService} from './modules/shared/services/hide-main-nav.service';
import {WsUsersService} from './modules/shared/services/ws-users.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
  private routerEvents$: Subscription | undefined;

  environment = environment;
  form?: FormGroup;
  form$?: Subscription;

  constructor(
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    private useMockDataService: UseMockDataService,
    private ngSelectConfig: NgSelectConfig,
    private mockHttpClientPipe: MockHttpClientPipe,
    private matDialog: MatDialog,
    public userService: UserService,
    public mainNavService: HideMainNavService,
    public wsUsersService: WsUsersService,
  ) {
    this.ngSelectConfig.appendTo = 'body';
    moment.locale('nl');
  }

  ngOnInit(): void {
    this.tryLoginUserFromLocalStorage();
    this.subscribeToRouter();
    this.initMockDataForm();
  }

  private tryLoginUserFromLocalStorage() {
    const localStorageUser = localStorage.getItem(appConfig.localStorageKeys.user);
    if (!localStorageUser) return;
    this.userService.setUser(JSON.parse(localStorageUser) as User);
  }

  private subscribeToRouter() {
    this.routerEvents$ = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.changeDetectorRef.detectChanges();
      } else if (event instanceof NavigationEnd) {
        localStorage.setItem(appConfig.localStorageKeys.lastSuccessFulNavigationUrl, event.urlAfterRedirects);
      } else if (event instanceof NavigationCancel || event instanceof NavigationError) {
        localStorage.removeItem(appConfig.localStorageKeys.lastSuccessFulNavigationUrl);
      }
    });
  }

  private initMockDataForm() {
    if (!this.environment.features.canUseMockData) return;

    this.form = new FormGroup({
      useMockData: new FormControl(this.useMockDataService.useMockData)
    });

    this.form$ = this.form.valueChanges.subscribe(form => {
      localStorage.setItem(appConfig.localStorageKeys.useMockData, JSON.stringify(form.useMockData));
      this.userService.unSetUser();
      location.reload();
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => document.getElementById('loading-wrapper')?.remove(), 400);
  }

  onReloadClick() {
    location.reload();
  }

  onLogoutClick() {
    this.mockHttpClientPipe.transform({
      method: 'POST',
      url: 'logout',
      comment: 'logout',
      mockReturnData: null,
    }).subscribe({
      next: () => this.handleLogOut(),
      error: () => this.handleLogOut()
    });
  }

  private handleLogOut() {
    this.matDialog.closeAll();
    this.userService.unSetUser();
    this.router.navigateByUrl('/');
  }

  ngOnDestroy(): void {
    this.routerEvents$?.unsubscribe();
  }
}
