<img class="icon"
     alt="SPSS"
     ngSrc="../../assets/img/spss-logo.jpg"
     width="158"
     height="100"/>

<h2 class="title">{{title}}</h2>

<button mat-icon-button
        color="warn"
        matTooltip="Sluiten"
        [mat-dialog-close]=""
        class="close-btn">
  <mat-icon>close</mat-icon>
</button>
