import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {UserGuard} from './modules/shared/guards/user.guard';
import {NoUserGuard} from './modules/shared/guards/no-user.guard';

const routes: Routes = [
  {
    path: 'public',
    canActivate: [NoUserGuard],
    loadChildren: () => import('./modules/public/public.module').then(m => m.PublicModule)
  },
  {
    path: 'private',
    canActivate: [UserGuard],
    loadChildren: () => import('./modules/private/private.module').then(m => m.PrivateModule)
  },
  {
    path: 'api-off-line',
    loadComponent: () => import('./pages/api-off-line/api-off-line.component').then(c => c.ApiOffLineComponent)
  },
  {
    path: '**',
    redirectTo: 'public'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRouting {
}
