<app-version-checker></app-version-checker>

<div class="app-wrapper">
  <mat-toolbar *ngIf="(mainNavService.mainNavHidden$ | async) === false"
               class="mat-elevation-z2"
               color="primary">
    <a class="image"
       routerLink="/dashboard">
      <img alt="SPSS logo"
           ngSrc="../../assets/img/spss-logo.jpg"
           [priority]="true"
           width="158"
           height="100"/>
    </a>

    <app-user-menu *ngIf="userService.user$ | async"></app-user-menu>

    <form *ngIf="environment.features.canUseMockData && form"
          [formGroup]="form"
          class="mock-data-form"
          [style.margin-left]="(userService.user$ | async) ? '0' : 'auto'">
      <mat-slide-toggle formControlName="useMockData"
                        color="accent"
                        class="no-space-bottom">
        <b class="mock-data-label color--white">MOCK</b>
      </mat-slide-toggle>
    </form>


    <button *ngIf="userService.user$ | async as user; else showRefreshIcon"
            mat-icon-button
            [matMenuTriggerFor]="desktopMenu"
            matTooltip="{{user?.name}}"
            class="desktop-menu-trigger color--notification">
      <mat-icon [matBadge]="(wsUsersService.wsUsers$ | async)?.length || undefined"
                matBadgeColor="accent">person
      </mat-icon>
    </button>

    <mat-menu #desktopMenu="matMenu"
              backdropClass="custom-mat-backdrop"
              class="custom-mat-panel">
      <button mat-menu-item
              (click)="onReloadClick()">
        <mat-icon>refresh</mat-icon>
        Herlaad pagina
      </button>
      <button mat-menu-item
              (click)="onLogoutClick()">
        <mat-icon>exit_to_app</mat-icon>
        Uitloggen
      </button>

      <app-ws-users></app-ws-users>
    </mat-menu>

    <ng-template #showRefreshIcon>
      <button mat-icon-button
              color="accent"
              class="reload-btn"
              matTooltip="Herlaad applicatie"
              (click)="onReloadClick()">
        <mat-icon>sync</mat-icon>
      </button>
    </ng-template>

    <span class="app-version">v{{environment.version}}</span>

  </mat-toolbar>

  <div class="app-main-content">
    <router-outlet></router-outlet>
  </div>
</div>
