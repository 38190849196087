import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';

import * as moment from 'moment';

let totalServiceWorkers = 0;
let totalServiceWorkersUnregistered = 0;
let swFound = false;

const installAndUpdateCustomSw = () => {
  if (swFound) return;
  navigator.serviceWorker.register('app-service-worker.js', {scope: '/'}).then(function (registration) {
    console.info('app-service-worker.js registered');
  }, function (err) {
    console.info('app-service-worker.js registration failed: ', err);
  });
}

const onUnregisteredServiceWorker = () => {
  totalServiceWorkersUnregistered++;
  console.info(`Service worker ${totalServiceWorkersUnregistered} of ${totalServiceWorkers} deleted`);

  if (totalServiceWorkers !== totalServiceWorkersUnregistered) return;
  installAndUpdateCustomSw();
}

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.getRegistrations().then(function (registrations) {
    if (registrations.length) {
      totalServiceWorkers = registrations.length;
      for (let registration of registrations) {
        const scriptUrl = registration?.active?.scriptURL || '';
        if (scriptUrl.split('/app-service-worker.js').length > 1) {
          swFound = true;
          registration.update().then(() => {
            console.info('app-service-worker.js updated');
          });
        } else {
          registration.unregister().then(() => {
            console.info('unregistered service worker:', registration);
            onUnregisteredServiceWorker()
          });
        }
      }
    } else {
      installAndUpdateCustomSw();
    }
  });
}

if (environment.isProduction) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

Date.prototype.toJSON = function () {
  return moment(this).format();
};
