import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment/moment';

@Pipe({
  name: 'isToday',
  standalone: true
})
export class IsTodayPipe implements PipeTransform {

  transform(date: Date): 'isToday' | 'isNotToday' {
    return moment(date).isSame(moment(), 'day') ? 'isToday' : 'isNotToday';
  }

}
