<a routerLink="/private/ponies"
   routerLinkActive="is-active">
  <mat-icon inline>military_tech</mat-icon>
  <span class="label">Pony's</span>
</a>

<a routerLink="/private/customers"
   routerLinkActive="is-active">
  <mat-icon inline>people</mat-icon>
  <span class="label">Klanten</span>
</a>
