import {OnDestroy, Pipe, PipeTransform} from '@angular/core';
import {BehaviorSubject, interval, Subscription} from 'rxjs';

@Pipe({
  name: 'timeAgo'
})
export class TimeAgoPipe implements PipeTransform, OnDestroy {
  interval$?: Subscription;
  result$ = new BehaviorSubject<string>('');

  transform(pastDate: Date): BehaviorSubject<string> {
    this.result$.next(this.getResult(pastDate));
    this.interval$?.unsubscribe();
    this.interval$ = interval(1000).subscribe(() => {
      this.result$.next(this.getResult(pastDate));
    });

    return this.result$;
  }

  private getResult(pastDate: Date): string {
    let seconds: number = Math.floor(((new Date()).getTime() - pastDate.getTime()) / 1000);
    let interval: number = Math.floor(seconds / 31536000);

    if (interval > 1) return this.transformCalculation(interval, 'jaren', 'jaar');

    interval = Math.floor(seconds / 2592000);
    if (interval > 1) return this.transformCalculation(interval, 'maanden', 'maand');

    interval = Math.floor(seconds / 86400);
    if (interval > 1) return this.transformCalculation(interval, 'dagen', 'dag');

    interval = Math.floor(seconds / 3600);
    if (interval > 1) return this.transformCalculation(interval, 'uren', 'uur');
    interval = Math.floor(seconds / 60);
    if (interval > 1) return this.transformCalculation(interval, 'minuten', 'minuut');

    const secondsRemaining = Math.floor(seconds);

    if (secondsRemaining > 0) return this.transformCalculation(secondsRemaining, 'seconde', 'seconden');

    return 'zojuist';
  }


  private transformCalculation(interval: number, singular: string, plural: string): string {
    return `${interval} ${interval > 1 ? plural : singular} geleden`;
  }

  ngOnDestroy(): void {
    this.interval$?.unsubscribe();
  }

}
