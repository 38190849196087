import { Component } from '@angular/core';
import {WsUsersService} from '../../modules/shared/services/ws-users.service';

@Component({
  selector: 'app-ws-users',
  templateUrl: './ws-users.component.html',
  styleUrls: ['./ws-users.component.scss']
})
export class WsUsersComponent {
  constructor(
    public wsUsersService: WsUsersService
  ) {}
}
