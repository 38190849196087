import {Pipe, PipeTransform} from '@angular/core';
import {DecimalPipe} from "@angular/common";
import {isNumeric} from '../../../../utils/is-numeric';


@Pipe({
  name: 'daysHoursMinutes',
  standalone: true,
})
export class DaysHoursMinutesPipe implements PipeTransform {

  constructor(
    private decimalPipe: DecimalPipe
  ) {
  }

  static getText(
    negativeInput: boolean,
    realDays: number,
    realHours: number,
    realMinutesPadded: string,
    maxResolution: 'days' | 'hours' = 'days',
    decimalPipe: DecimalPipe
  ): string {
    let text = '';
    if (negativeInput) text += '-';
    if (maxResolution === 'days') {
      if (realDays) text += `${realDays}d `;
    }

    let hours: number | string = realHours;

    if (maxResolution === 'hours') {
      if (realDays > 0) {
        hours = decimalPipe.transform(realHours + (24 * realDays)) || 0;
      }
    }

    text += `${hours}:${realMinutesPadded}`;
    return text;
  }

  transform(minutesToConvert: number, maxResolution: 'days' | 'hours' = 'days'): { text: string, days: number, hours: number, minutes: number } {
    if (!isNumeric(minutesToConvert)) {
      return {text: '-', days: 0, hours: 0, minutes: 0};
    }

    const negativeInput = (minutesToConvert < 0);
    minutesToConvert = Math.abs(minutesToConvert);

    const days = Math.floor(minutesToConvert / (24 * 60));
    const remainderDays = minutesToConvert % (24 * 60);
    const hours = Math.floor(remainderDays / 60);
    const minutes = minutesToConvert - (days * 24 * 60) - (hours * 60);
    const minutesPadded = this.decimalPipe.transform(minutes, '2.0-0') || '0';

    return {
      text: DaysHoursMinutesPipe.getText(negativeInput, days, hours, minutesPadded, maxResolution, this.decimalPipe),
      days: negativeInput ? (days * -1) : days,
      hours: negativeInput ? (hours * -1) : hours,
      minutes: negativeInput ? (minutes * -1) : minutes
    };
  }
}
