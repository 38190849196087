import {Component, Input} from '@angular/core';
import {SharedModule} from '../../shared.module';

@Component({
  selector: 'app-dialog-header',
  templateUrl: './dialog-header.component.html',
  styleUrls: ['./dialog-header.component.scss'],
  standalone: true,
  imports: [
    SharedModule
  ],
})
export class DialogHeaderComponent {
  @Input() title!: string;
}
