import {Directive, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';

@Directive({
  selector: '[appNgxOnInitOnDestroy]',
  standalone: true
})
export class NgxOnInitOnDestroyDirective implements OnInit, OnDestroy {
  @Output() init = new EventEmitter<void>();
  @Output() destroy = new EventEmitter<void>();

  constructor() {
  }

  ngOnInit() {
    this.init.emit();
  }

  ngOnDestroy() {
    this.destroy.emit();
  }
}
