<ng-container *ngIf="wsUsersService.wsUsers$ | async as wsUsers">
  <div *ngIf="wsUsers.length"
       class="ws-users">
    <div><b>Gebruiker</b></div>
    <div><b>Actief sinds</b></div>
    <div><b>Laatste actie</b></div>
    <div></div>

    <ng-container *ngFor="let user of wsUsers">
      <div>{{user.name}}</div>
      <div>{{user.connectDate | date:'dd-MM hh:mm:ss'}}</div>
      <div>{{user.latestAction}}</div>
      <div>{{user.latestActiveDate | date:'dd-MM hh:mm:ss'}}</div>
    </ng-container>
  </div>
</ng-container>

