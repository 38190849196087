<mat-dialog-content>

  <app-dialog-header title="Actie niet voltooid"></app-dialog-header>

  <pre class="error-message">
    <ng-container *ngIf="url">
      {{url}}<br/><br/>
    </ng-container>
    <ng-container *ngIf="message">
      {{message}}<br/><br/>
    </ng-container>
    <ng-container *ngIf="error">
      {{error}}
    </ng-container>
  </pre>
</mat-dialog-content>

<mat-dialog-actions>
  <button *ngIf="isTimeOutError" (click)="onReloadClick()" mat-flat-button color="primary">
    <mat-icon>cached</mat-icon>
    <span>Herlaad pagina</span>
  </button>

  <button mat-raised-button color="accent" (click)="close()">
    <mat-icon>clear</mat-icon>
    Sluiten
  </button>
</mat-dialog-actions>
