import {DEFAULT_CURRENCY_CODE, LOCALE_ID} from '@angular/core';
import {AppComponent} from './app.component';
import {BrowserModule} from '@angular/platform-browser';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {AppRouting} from './app.routing';
import {ApiInterceptor} from './api-interceptor.service';
import {MAT_FORM_FIELD_DEFAULT_OPTIONS} from '@angular/material/form-field';
import {MAT_DIALOG_DEFAULT_OPTIONS} from '@angular/material/dialog';
import {DatePipe, DecimalPipe, JsonPipe, registerLocaleData} from '@angular/common';
import localeNl from '@angular/common/locales/nl';
import {DateAdapter, MAT_DATE_LOCALE} from '@angular/material/core';
import {CustomDateAdapter} from './custom-date-adapter';
import {VersionCheckerComponent} from './version-checker.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MAT_PAGINATOR_DEFAULT_OPTIONS, MatPaginatorIntl} from '@angular/material/paginator';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {environment} from '../environments/environment';
import {NgModule} from '@angular/core';
import {MockHttpClientPipe} from './modules/shared/pipes/mock-data-fetch.pipe';
import {UseMockDataService} from './modules/shared/services/use-mock-data.service';
import {UserService} from './modules/shared/services/user.service';
import {DaysHoursMinutesPipe} from './modules/shared/pipes/days-hours-minutes.pipe';
import {IsTodayPipe} from './modules/shared/pipes/is-today.pipe';
import {UserGuard} from './modules/shared/guards/user.guard';
import {SharedModule} from './modules/shared/shared.module';
import {UserMenuComponent} from './components/user-menu/user-menu.component';
import {WebSocketService} from './modules/shared/services/web-socket.service';
import {WsUsersComponent} from './components/ws-users/ws-users.component';

@NgModule({
  declarations: [
    AppComponent,
    VersionCheckerComponent,
    UserMenuComponent,
    WsUsersComponent,
    MockHttpClientPipe
  ],
  imports: [
    // Angular
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,

    SharedModule,

    // Routing
    AppRouting,
  ],
  providers: [
    // Services
    UseMockDataService,
    UserService,
    WebSocketService,

    // Pipes
    DecimalPipe,
    DatePipe,
    MockHttpClientPipe,
    DaysHoursMinutesPipe,
    IsTodayPipe,
    JsonPipe,

    // Guards
    UserGuard,

    // Other providers
    {provide: DateAdapter, useClass: CustomDateAdapter},

    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptor,
      multi: true
    },
    {provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR'},
    {provide: LOCALE_ID, useValue: 'nl-NL'},
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {subscriptSizing: 'dynamic'}
    },
    {
      provide: MAT_PAGINATOR_DEFAULT_OPTIONS,
      useValue: {formFieldAppearance: 'fill'}
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        maxWidth: null,
        maxHeight: null,
        panelClass: 'app-dialog',
      }
    },
    {provide: MAT_DATE_LOCALE, useValue: 'nl-NL'},
    {
      provide: MatPaginatorIntl,
      useValue: Object.assign(new MatPaginatorIntl(), {
        itemsPerPageLabel: 'Per pagina',
        nextPageLabel: 'Volgende',
        previousPageLabel: 'Vorige',
        firstPageLabel: 'Eerste',
        lastPageLabel: 'Laatste',
        getRangeLabel: (page: number, pageSize: number, length: number) => {
          if (length === 0 || pageSize === 0) {
            return `0 van ${length}`;
          }
          length = Math.max(length, 0);
          const startIndex = page * pageSize;
          const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
          return `${startIndex + 1}–${endIndex}/${length}`;
        }
      })
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
    registerLocaleData(localeNl);
  }
}

// required for AOT compilation
// tslint:disable-next-line:typedef
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(
    http,
    '/assets/i18n/',
    `.json?cacheBuster=${environment.version}`
  );
}

